import React, { useContext, useState, useEffect } from 'react';
import './Header.css';
import { UserContext } from '../store/user-context';
import { Container, Nav, Navbar, NavDropdown, Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import LogoutButton from '../components/Logout';
import UsuarioClave from '../components/UsuarioClave'; // Importa el componente
import logo from '../assets/ciab_logo.png';
import menuadm from '../data_files/menuadm';
import menuadm2 from '../data_files/menuadm2';
import menuadm3 from '../data_files/menuadm3';
import menuadm4 from '../data_files/menuadm4';
import menugen from '../data_files/menugen';
import { useDispatch, useSelector } from "react-redux";
import { fetchCuotasPendientes } from '../actions/cuotaActions'; // Importamos

function Header() {
  console.log('Header component rendering'); // Traza inicial

  const { userState } = useContext(UserContext);
  const { id, user, roles = [], foto } = userState; // Aseguramos que roles
  const cuotasPendientes = useSelector((state) => state.cuota.cuotasPendientes);
  console.log('User context:', userState); // Muestra el estado del usuario

  const isAdmin = roles.includes('admin');
  const rolus = roles[0];
  console.log('Is Admin:', isAdmin, 'User Role:', rolus); // Verifica el rol del

  const menus1 = () => {
    switch (rolus) {
      case 'admin':
        return menuadm;
      case 'gerente':
        return menugen;
      case 'ADMIN2':
        return menuadm2;
      case 'ADMIN3':
        return menuadm3;
      case 'ADMIN4':
        return menuadm4;
      default:
        return menuadm4;
    }
  };
  const menus = menus1();
  console.log('Menus:', menus); // Verifica qu�� men��s se cargan seg��n el rol

  const navigate = useNavigate();
  const dispatch = useDispatch(); // Obtenemos la funci��n dispatch
  const [showUsuarioClave, setShowUsuarioClave] = useState(false); // Esta
  const [showCuotasModal, setShowCuotasModal] = useState(false); // Estado
  const [ci, setCi] = useState('');
  const [fecha, setFecha] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Cuotas modal visibility:', showCuotasModal); // Monitorea
    if (showCuotasModal) {
      const today = new Date();
      const currentDate = today.toISOString().substr(0, 10);
      setFecha(currentDate);
      setCi('');
      setResult(null);
      setError(null);
    }
  }, [showCuotasModal]);

  const handleNavigation = (path) => {
    console.log('Navigating to:', path); // Rastrea la navegaci��n del usuario
    navigate(path, { state: { fromHeader: true } });
  };

  const handleShowUsuarioClave = () => {
    console.log('Showing UsuarioClave modal');
    setShowUsuarioClave(true);
  };

  const handleCloseUsuarioClave = () => {
    console.log('Closing UsuarioClave modal');
    setShowUsuarioClave(false);
  };

  const handleShowCuotasModal = () => {
    console.log('Opening cuotas modal');
    setShowCuotasModal(true);
  };

  const handleCloseCuotasModal = () => {
    console.log('Closing cuotas modal');
    setShowCuotasModal(false);
    setCi(''); // Limpiar el CI al cerrar
    setFecha(''); // Limpiar la fecha al cerrar
    setResult(null);
    setError(null);
  };

  const handleCheckCuotas = () => {
    console.log('Checking cuotas for CI:', ci, 'Date:', fecha); // Muestra
    dispatch(fetchCuotasPendientes(ci, fecha))
      .then(data => {
        console.log('Cuotas received:', data); // Monitorea la respuesta d
        setError(null);
        setShowCuotasModal(true); // Abrimos el modal al obtener la respuesta
      })
      .catch(err => {
        console.error('Error fetching cuotas:', err); // Monitorea el erro
        setError(err.message);
        setResult(null);
      });
  };

  return (
    <header>
      <Navbar expand="lg" className="navbar header">
        <Container className="contenedor-nav">
          <Navbar.Brand>
            <Link to="/" className="brand-link">
              <img
                alt="Logo CIAB"
                src={logo}
                width="120"
                height="120"
                className="d-inline-block align-top"
              />
              <span className="brand-text">CIAB Tarija</span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="principal-nav" />
          <Navbar.Collapse id="principal-nav">
            {user && (
              <Nav className="me-auto">
                {menus.map((menu) => (
                  <li className="nav-item dropdown" key={menu.title}>
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {menu.title}
                    </a>
                    <ul className="dropdown-menu">
                      {menu.sections.map((section) => (
                        <div key={section.name}>
                          <p className="dropdown-header">{section.name}</p>
                          {section.options.map((option) => (
                            <li key={option.path}>
                              <a
                                className="dropdown-item"
                                href="#"
                                role="button"
                                onClick={() => handleNavigation(option.path)}
                              >
                                {option.name}
                              </a>
                            </li>
                          ))}
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                        </div>
                      ))}
                    </ul>
                  </li>
                ))}
              </Nav>
            )}
            <Nav className="ml-auto d-flex">
              {!user ? (
                <>
                  <button className="button btn-accent-outline" onClick={handleShowCuotasModal}>
                    Consultar Cuotas
                  </button>
                  <Link to="/login">
                    <button className="button btn-accent-outline">Iniciar Sesi&oacute;n</button>
                  </Link>
                </>
              ) : (
                <div className="d-flex nav-item">
                  {foto && (
                    <img
                      alt="Foto del usuario"
                      src={foto}
                      width="50"
                      height="50"
                      className="d-inline-block align-top"
                      style={{ borderRadius: '50%', marginRight: '10px' }}
                    />
                  )}

                  <NavDropdown title={user} id="user-dropdown">
                    <NavDropdown.Item as="div">
                      <LogoutButton />
                    </NavDropdown.Item>
                    <NavDropdown.Item as="div" className="mt-2">
                      <button onClick={handleShowUsuarioClave} className="button btn-accent-outline">
                        Cambiar Contrase&ntilde;a
                      </button>
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {user && (
        <UsuarioClave
          show={showUsuarioClave}
          handleClose={handleCloseUsuarioClave}
          selectedId={id} // Pasa el ID del usuario al modal
        />
      )}
      <Modal show={showCuotasModal} onHide={handleCloseCuotasModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cuotas Pendientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="CI del socio"
            value={ci}
            onChange={(e) => setCi(e.target.value)}
            className="form-control mb-3"
          />
          <input
            type="date"
            value={fecha}
            onChange={(e) => setFecha(e.target.value)}
            className="form-control mb-3"
          />
          <button onClick={handleCheckCuotas} className="button btn-accent mb-3">
            Consultar
          </button>
          {cuotasPendientes && (
            <div>
              <p>Nombre: {cuotasPendientes.nombres} {cuotasPendientes.apPat} {cuotasPendientes.apMat}</p>
              <p>Cuotas Pendientes: {cuotasPendientes.cuotasPendientes}</p>
            </div>
          )}
          {error && <p className="text-danger">{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <button className="button btn-cancel" onClick={handleCloseCuotasModal}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </header>
  );
}

export default Header;
